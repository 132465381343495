import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    mapkitToken: String,
    stations: Array,
    logoImageUrl: String,
    selectedStationId: Number,
  }

  connect() {

    let token = this.mapkitTokenValue
    let stations = this.stationsValue
    let logo_image_url = this.logoImageUrlValue
    let selected_station_id = this.selectedStationId

    mapkit.init({
      authorizationCallback: function(done) {
        done(token)
      }
    });

    var map = new mapkit.Map("map");

    // When showing the translucent side panel, shift the map a little to the right.
    if (window.innerWidth >= 768) {
      map.padding = new mapkit.Padding({top: 0, left: 600, bottom: 0, right: 0})
    }
    // cameraBoundary: mapkit.MapRect(x, y, width, height)

    // When on mobile, shift the map a little up to make room for the tab bar.
    if (navigator.userAgent.includes("Jumpstart") && window.innerWidth < 768) {
      map.padding = new mapkit.Padding({top: 0, left: 0, bottom: 50, right: 0})
    }

    var marker_annotations = []
    stations.forEach((station) => {
      if (station.longitude && station.latitude) {
        var coordinate = new mapkit.Coordinate(station.latitude, station.longitude)
        var marker_annotation = new mapkit.ImageAnnotation(coordinate, {
          title: station.name,
          url: {
            2: logo_image_url
          }
        })

        marker_annotation.addEventListener("select", event => {
          let target_path = `/stations/${station.id}?select_current_station=${this.is_selecting_current_station()}`
          if (navigator.userAgent.includes("Jumpstart")) {
            Turbo.visit(target_path)
          } else {
            document.getElementById("bottom-sheet").classList.add("active")
            document.getElementById("station-body").setAttribute("src", target_path)
          }
        })

        if (station.id == selected_station_id) {
          marker_annotation.trigger("select")
        }

        marker_annotations.push(marker_annotation)
      }
    })

    map.showsUserLocationControl = true
    map.tintColor = '#2D74AB'
    map.showItems(marker_annotations)

  }

  maximize_bottom_sheet() {
    document.getElementById("bottom-sheet").classList.add("full")
  }

  close_bottom_sheet() {
    document.getElementById("bottom-sheet").classList.remove("full", "active")
  }

  reduce_bottom_sheet() {
    document.getElementById("bottom-sheet").classList.remove("full")
  }

  bottom_sheet_scrolled(event) {
    if (document.getElementsByClassName("bottom-sheet-body")[0].scrollTop <= 0) {
      this.reduce_bottom_sheet()
    }
    if (document.getElementsByClassName("bottom-sheet-body")[0].scrollTop > 0) {
      this.maximize_bottom_sheet()
    }
  }

  is_selecting_current_station() {
    const queryParams = new URLSearchParams(window.location.search)
    return queryParams.get('select_current_station') === "true";
  }
}