import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    if (sessionStorage.getItem("screen_has_been_shown") !== "true") {
      setTimeout(() => {
        this.show_welcome_screen()
      }, 200)

      // Mark the task as performed in this session
      sessionStorage.setItem("screen_has_been_shown", "true");
    }
  }

  // We are navigating to the welcome screen via Turbo. The mobile app will know to
  // open that path in a modal window based on the rules given in our
  // path_configurations_controller.rb.
  //
  show_welcome_screen() {
    Turbo.visit("/welcome_modal")
  }
}