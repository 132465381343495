import StarRating from 'star-rating.js/src/index.js'

const star_ratings = []

function init_star_rating() {
  document.querySelectorAll('.star-rating').forEach((element) => {
    let options = {
      maxStars: 5,
      stars: function (el, item, index) {
        el.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path class="gl-star-full" fill="#ffb900" d="M9 0L6 6l-6 .75 4.12 4.62L3 18l6-3 6 3-1.13-6.63L18 6.75 12 6z"/></svg>'
      },
      tooltip: false,
    }

    let star_rating = new StarRating(element, options);
    star_ratings.push(star_rating)
  })
}

function destroy_star_rating() {
  star_ratings.forEach((star_rating) => {
    star_rating.destroy()
  })
}

document.addEventListener('DOMContentLoaded', init_star_rating)
document.addEventListener('turbo:load', init_star_rating)
document.addEventListener('turbo:frame-load', init_star_rating)
