import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => { this.requestGeolocation() }, 2000)
  }

  requestGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.sendPosition.bind(this))
    }
  }

  sendPosition(position) {
    const { latitude, longitude } = position.coords
    this.updateLocation(latitude, longitude)
  }

  updateLocation(latitude, longitude) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch('/api/v1/geo_locations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ latitude, longitude })
    })
  }
}