import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { German } from "flatpickr/dist/l10n/de.js";

// Apply global configuration for Flatpickr
flatpickr.setDefaults({
  dateFormat: "Y-m-d H:i:s", // Adjust the format as needed (e.g., "Y-m-d H:i" for 24h)
  altFormat: "d.m.Y, H:i", // human-readable
  altInput: true,
  enableTime: true,         // Enable time selection
  time_24hr: true,          // Set 24-hour format
  locale: {
    ...German,
    firstDayOfWeek: 1,
  }
});
