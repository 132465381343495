import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

var lightbox

function open_last_photoswipe_image(e) {
  e.preventDefault()
  const images = document.querySelectorAll('.pswp-image')
  const lastImageIndex = images.length - 1
  lightbox.loadAndOpen(lastImageIndex)
}

function initPhotoSwipe() {
  lightbox = new PhotoSwipeLightbox({
    gallery: '.pswp-gallery',
    children: '.pswp-image',
    pswpModule: PhotoSwipe,
    bgOpacity: 1.0,
    preload: [1, 4],
    verticalDragRange: 0.2,
  });

  lightbox.init();

  lightbox.on('beforeOpen', (e) => {
    if (window.TurboNativeBridge) {
      window.TurboNativeBridge.postMessage("maximizeAndFixBottomSheet")
    }
  })

  lightbox.on('close', () => {
    if (window.TurboNativeBridge) {
      window.TurboNativeBridge.postMessage("releaseBottomSheet")
    }
  })
  if (document.getElementsByClassName('open_last_photoswipe_image')[0]) {
    document.getElementsByClassName('open_last_photoswipe_image')[0].addEventListener('click', open_last_photoswipe_image)
  }
}

function destroyPhotoSwipe() {
  if (lightbox) {
    lightbox.destroy();
    lightbox = null;
  }
  if (document.getElementsByClassName('open_last_photoswipe_image')[0]) {
    document.getElementsByClassName('open_last_photoswipe_image')[0].removeEventListener('click', open_last_photoswipe_image)
  }
}

document.addEventListener('DOMContentLoaded', initPhotoSwipe)
document.addEventListener('turbo:load', initPhotoSwipe)
document.addEventListener('turbo:frame-load', initPhotoSwipe)

document.addEventListener('turbo:before-cache', destroyPhotoSwipe)
document.addEventListener('turbo:before-fetch-request', destroyPhotoSwipe)
